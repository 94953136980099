module.exports = [{
      plugin: require('/Users/MecMartini/Sites/soulweb-frontend-gatsby/node_modules/gatsby-plugin-typography/gatsby-browser.js'),
      options: {"plugins":[],"pathToConfigModule":"src/utils/typography"},
    },{
      plugin: require('/Users/MecMartini/Sites/soulweb-frontend-gatsby/node_modules/gatsby-plugin-intl/gatsby-browser.js'),
      options: {"plugins":[],"path":"/Users/MecMartini/Sites/soulweb-frontend-gatsby/src/intl","languages":["en"],"defaultLanguage":"en","redirect":false},
    },{
      plugin: require('/Users/MecMartini/Sites/soulweb-frontend-gatsby/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-140138041-1"},
    },{
      plugin: require('/Users/MecMartini/Sites/soulweb-frontend-gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    }]

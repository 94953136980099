// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("/Users/MecMartini/Sites/soulweb-frontend-gatsby/src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("/Users/MecMartini/Sites/soulweb-frontend-gatsby/src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-index-js": () => import("/Users/MecMartini/Sites/soulweb-frontend-gatsby/src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-knowledge-js": () => import("/Users/MecMartini/Sites/soulweb-frontend-gatsby/src/pages/knowledge.js" /* webpackChunkName: "component---src-pages-knowledge-js" */),
  "component---src-pages-services-js": () => import("/Users/MecMartini/Sites/soulweb-frontend-gatsby/src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */)
}

exports.data = () => import(/* webpackChunkName: "pages-manifest" */ "/Users/MecMartini/Sites/soulweb-frontend-gatsby/.cache/data.json")


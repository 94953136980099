import React from 'react'
import { CookieBanner } from '@palmabit/react-cookie-law';

const CookieBannerWrapper = () => (
  <CookieBanner
    message="We are using cookies to give you the best experience on our website. Select which ones you want to accept:"
    statisticsOptionText="Analytical"
    marketingOptionText="Marketing"
    privacyPolicyLinkText="More about cookies..."
    policyLink="https://www.cookiesandyou.com/"
    onAccept = {() => {}}
    showPreferencesOption={false}
    onAcceptStatistics = {() => {}}
    onAcceptMarketing = {() => {}}
    onDeclineStatistics = {() => {}}
    onDeclineMarketing = {() => {}}
    styles={{
      dialog: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        zIndex: 100000,
        backgroundColor: 'rgb(248, 247, 247)',
        padding: '10px'
      }
    }}
  />
)

export default CookieBannerWrapper
